const UniversityTable = () => {
  return (
    <div className="relative">
      {/* 背景の桜SVG */}
      <div className="absolute inset-0 flex justify-center items-center opacity-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="200"
          height="200"
          viewBox="0 0 24 24"
          fill="pink"
        >
          <path d="M12 2c1.1 0 2 .9 2 2 0 .45-.15.86-.4 1.2.58-.12 1.22-.2 1.88-.2 2.21 0 4 1.79 4 4 0 .99-.37 1.9-.98 2.6.2-.03.4-.06.6-.06 1.1 0 2 .9 2 2 0 1.24-.95 2.25-2.17 2.38 0 .07.01.14.01.22 0 2.21-1.79 4-4 4-1.06 0-2.03-.42-2.74-1.1-.71.68-1.68 1.1-2.74 1.1-2.21 0-4-1.79-4-4 0-.08.01-.15.01-.22C4.95 16.25 4 15.24 4 14c0-1.1.9-2 2-2 .2 0 .4.03.6.06-.61-.7-.98-1.61-.98-2.6 0-2.21 1.79-4 4-4 .66 0 1.3.08 1.88.2A1.99 1.99 0 0112 2z" />
        </svg>
      </div>

      {/* 国公立大学テーブル */}
      <div className="mb-10">
        <h2 className="text-2xl font-bold text-red-600 text-center mb-4">国公立大学</h2>
        <table className="table-auto border-collapse border border-red-300 w-full text-left">
          <thead>
            <tr className="bg-red-500 text-white">
              <th className="border border-red-300 px-4 py-2">大学名</th>
              <th className="border border-red-300 px-4 py-2">人数</th>
            </tr>
          </thead>
          <tbody>
            {[
              ["埼玉大(経済)", "1名"],
              ["電気通信大(情報理工)", "1名"],
              ["京都大(法)", "1名"],
              ["京都大(工)", "1名"],
              ["神戸大(理)", "1名"],
              ["神戸大(医(作業療法))", "1名"],
              ["島根大(人間科学)", "1名"],
              ["島根大(生物資源)", "1名"],
              ["広島大(医(医))", "1名"],
              ["香川大(教育)", "1名"],
              ["愛媛大(法文)", "1名"],
              ["愛媛大(工)", "1名"],
              ["高知大(人文社会)", "5名"],
              ["高知大(教育)", "3名"],
              ["高知大(理工)", "1名"],
              ["高知大(医(看護))", "1名"],
              ["高知大(農林海洋)", "2名"],
              ["東京都立大(人文社会)", "1名"],
              ["東京都立大(理)", "1名"],
              ["静岡文化芸術大(デザイン)", "1名"],
              ["愛知県立大(外国語)", "1名"],
              ["大阪公立大(商)", "1名"],
              ["大阪公立大(工)", "1名"],
              ["神戸市外国語大(外国語)", "1名"],
              ["広島市立大(情報科学)", "1名"],
              ["高知工科大(経済・マネジメント)", "1名"],
              ["高知工科大(理工)", "1名"],
              ["高知県立大(社会福祉)", "1名"],
              ["", "現役生も含む"],
            ].map((row, index) => (
              <tr key={index} className={index % 2 === 0 ? "bg-red-100" : "bg-white"}>
                <td className="border border-red-300 px-4 py-2">{row[0]}</td>
                <td className="border border-red-300 px-4 py-2">{row[1]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* 私立大学テーブル */}
      <div>
        <h2 className="text-2xl font-bold text-red-600 text-center mb-4">私立大学</h2>
        <table className="table-auto border-collapse border border-red-300 w-full text-left">
          <thead>
            <tr className="bg-red-500 text-white">
              <th className="border border-red-300 px-4 py-2">大学名</th>
              <th className="border border-red-300 px-4 py-2">人数</th>
            </tr>
          </thead>
          <tbody>
            {[
              ["日本薬科大", "1名"],
              ["青山学院大", "1名"],
              ["北里大", "1名"],
              ["上智大", "1名"],
              ["中央大", "2名"],
              ["東海大", "1名"],
              ["東京医療保健大", "1名"],
              ["東京女子大", "1名"],
              ["東京農業大", "1名"],
              ["東京理科大", "1名"],
              ["日本歯科大", "1名"],
              ["日本獣医生命科学大", "1名"],
              ["法政大", "3名"],
              ["明星大", "1名"],
              ["立教大", "2名"],
              ["愛知医科大(医(医))", "1名"],
              ["愛知学院大", "1名"],
              ["中京大", "2名"],
              ["豊田工業大", "1名"],
              ["名古屋造形大", "1名"],
              ["日本赤十字豊田看護大", "1名"],
              ["京都外国語大", "2名"],
              ["京都産業大", "3名"],
              ["京都薬科大", "1名"],
              ["同志社大", "5名"],
              ["立命館大", "7名"],
              ["龍谷大", "3名"],
              ["大阪医科薬科大", "2名(うち医(医)1名)"],
              ["大阪経済大", "1名"],
              ["大阪電気通信大", "1名"],
              ["関西大", "1名"],
              ["関西外国語大", "1名"],
              ["近畿大", "11名"],
              ["関西福祉科学大", "1名"],
              ["関西学院大", "6名"],
              ["神戸薬科大", "3名"],
              ["岡山理科大", "3名"],
              ["吉備国際大", "1名"],
              ["就実大", "1名"],
              ["美作大", "1名"],
              ["比治山大", "1名"],
              ["福山大", "1名"],
              ["四国大", "1名"],
              ["徳島文理大", "5名"],
              ["松山大", "9名"],
              ["高知学園大", "1名"],
              ["福岡大", "1名"],
              ["", "現役生も含む"],

            ].map((row, index) => (
              <tr key={index} className={index % 2 === 0 ? "bg-red-100" : "bg-white"}>
                <td className="border border-red-300 px-4 py-2">{row[0]}</td>
                <td className="border border-red-300 px-4 py-2">{row[1]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UniversityTable;